import React from "react";

import "./BpPriceList.scss";

const BpPriceList = () => {
  return (
    <div className="bppricelist sect_padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="sect_header">
              <h2 className="title without_line">Services price</h2>
              <h6 className="desc full">
                Cosmetics applied to the face to enhance its appearance are
                often called make-up or makeup. Makeup services price below:
              </h6>
            </div>
            <div className="prices">
              <div className="price_single">
                <div className="name">
                Face Moisturizer
                </div>
                <div className="price">
                ₹ 199
                </div>
              </div>
              <div className="price_single">
                <div className="name">
                Face Brushes
                </div>
                <div className="price">
                ₹ 85
                </div>
              </div>
              <div className="price_single">
                <div className="name">
                Face Powder
                </div>
                <div className="price">
                ₹ 55
                </div>
              </div>
              <div className="price_single">
                <div className="name">
                Face Primer
                </div>
                <div className="price">
                ₹ 99
                </div>
              </div>
              <div className="price_single">
                <div className="name">
                Bronzer
                </div>
                <div className="price">
                ₹ 125
                </div>
              </div>
              <div className="price_single">
                <div className="name">
                Luminizer
                </div>
                <div className="price">
                ₹ 120
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="sect_header">
              <h2 className="title without_line">Before after Services</h2>           
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BpPriceList;
