import React from "react";

import "./BpContactForm.scss";

const BpContactForm = () => {
  return (
    <div
      className="bpcontactform"
      style={{
        backgroundImage: "url('./assets/img/beauty-parlour/form_bg.png')",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="left">
              <img src="./assets/img/beauty-parlour/form_left_img.png" alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="right sect_padding">
              <div className="sect_header">
                <h2 className="title without_line">
                  Book Your Appointment Today!
                </h2>
                <h6 className="desc full">
                  One of our experts will get back to you soon and answer any
                  questions you may have.
                </h6>
              </div>
              <div className="apo_form">
                <form action="">
                  <div className="row field_gap">
                    <div className="col-md-6">
                      <div className="field_single">
                        <label htmlFor="">Name (required)</label>
                        <input type="text" placeholder="Enter your name" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="field_single">
                        <label htmlFor="">Phone (required)</label>
                        <input type="number" placeholder="Enter your phone number" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="field_single">
                        <label htmlFor="">Email (optional)</label>
                        <input type="email" placeholder="Enter your email" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="field_single">
                        <label htmlFor="">Service</label>
                       <select name="" id="">
                        <option value="">Select</option>
                        <option value="">Foundation</option>
                        <option value="">Facial</option>
                        <option value="">Face Powder</option>
                        <option value="">Face Primer</option>
                        <option value="">Bleach</option>
                        <option value="">Other</option>
                       </select>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="field_single">
                        <label htmlFor="">Message (optional)</label>
                        <textarea name="" id=""></textarea>
                      </div>
                    </div>
                    <div className="col-12">
                    <button className="theme_btn btn_fill">Submit Now</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
       
          </div>
        </div>
      </div>
    </div>
  );
};

export default BpContactForm;
