import React from "react";

import "./BpGallery.scss";

const BpGallery = () => {
  return (
    <div className="bpgallery sect_padding">
      <div className="container-fluid">
        <div className="sect_header text-center">
          <h2 className="title">
            Gallery
            <img
              src="./assets/img/beauty-parlour/icon/title_icon.png"
              alt=""
              className="title_icon"
            />
          </h2>
          <h6 className="desc">
            Lorem ipsum dolor sittem ametamngcing elit, per sed do eiusmoad
            teimpor sittem elit inuning ut sed sittem do eiusmod.
          </h6>
        </div>
        <div className="gallery_parent">
          <div className="gallery_single">
            <img
              src="./assets/img/beauty-parlour/portfolio-image-1.jpg"
              alt=""
            />
          </div>
          <div className="gallery_single">
            <img
              src="./assets/img/beauty-parlour/portfolio-image-2.jpg"
              alt=""
            />
          </div>
          <div className="gallery_single">
            <img
              src="./assets/img/beauty-parlour/portfolio-image-3.jpg"
              alt=""
            />
          </div>
          <div className="gallery_single">
            <img
              src="./assets/img/beauty-parlour/portfolio-image-4.jpg"
              alt=""
            />
          </div>
          <div className="gallery_single">
            <img
              src="./assets/img/beauty-parlour/portfolio-image-5.jpg"
              alt=""
            />
          </div>
          <div className="gallery_single">
            <img
              src="./assets/img/beauty-parlour/portfolio-image-6.jpg"
              alt=""
            />
          </div>
          <div className="gallery_single">
            <img
              src="./assets/img/beauty-parlour/portfolio-image-7.jpg"
              alt=""
            />
          </div>
          <div className="gallery_single">
            <img
              src="./assets/img/beauty-parlour/portfolio-image-8.jpg"
              alt=""
            />
          </div>
        </div>
        <div className="text-center mt-4">
            <button className="theme_btn btn_fill">
                Load More
            </button>
        </div>
      </div>
    </div>
  );
};

export default BpGallery;
