import React from 'react'
import { BrowserRouter } from "react-router-dom";

// import all templates 
import RestaurantRoutes from '../templates/restaurant/restaurant-routes/RestaurantRoutes';
import BeautyParlourRoutes from '../templates/beautyParlour/beautyParlourRoutes/BeautyParlourRoutes';


const Router = () => {
    return (
        <BrowserRouter>
            {/* <RestaurantRoutes /> */}
            <BeautyParlourRoutes />
            
        </BrowserRouter>
    )
}

export default Router
