import React from "react";

import "./BpItemPurchase.scss";

const BpItemPurchase = () => {
  return (
    <div className="bpitempurchase sect_padding">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="left">
              <img
                src="./assets/img/beauty-parlour/purchase.png"
                alt=""
                className="w-100"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="sect_header text-center">
              <h2 className="title without_line">
              Need any type of cosmetic! Please          
              </h2>
           
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BpItemPurchase;
