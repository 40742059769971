import React from "react";

import "./BpTestimonial.scss";

const BpTestimonial = () => {
  return (
    <div
      className="bptestimonial sect_padding"
      style={{
        backgroundImage:
          "url('./assets/img/beauty-parlour/testimonial-bg.png')",
      }}
    >
      <div className="container">
        <div className="about_us_inner">
          <div className="sect_header text-center">
            <h2 className="title">
              Customer Testimonials
              <img
                src="./assets/img/beauty-parlour/icon/title_icon.png"
                alt=""
                className="title_icon"
              />
            </h2>
            <h6 className="desc">
              Lorem ipsum dolor sittem ametamngcing elit, per sed do eiusmoad
              teimpor sittem elit inuning ut sed sittem do eiusmod.
            </h6>
          </div>
          <div className="testimonial_card">
            <div className="tc_single text-center">
              <div className="t_pic">
                <img src="./assets/img/beauty-parlour/client-1.png" alt="" />
              </div>
              <p className="t_desc regular">
                Lorem ipsum dolor sittem ametamngcing elit, per sed do eiusmoad
                teimpor sittem elit inuning ut sed sittem do eiusmod.Lorem ipsum
                dolor sittem ametamngcing elit, per sed do eiusmoad teimpor
                sittem elit inuning ut sed sittem do eiusmod.Lorem ipsum dolor
                sittem ametamngcing elit, per sed do eiusmoad teimpor sittem
                elit inuning ut sed sittem do eiusmod.Lorem ipsum dolor sittem
                ametamngcing elit, per sed do eiusmoad teimpor sittem elit
                inuning ut sed sittem do eiusmod.Lorem ipsum dolor sittem
                ametamngcing elit, per sed do eiusmoad teimpor sittem elit
                inuning ut sed sittem do eiusmod.
              </p>
              <h5 className="t_name ">Shivani Sharma</h5>
              <h6 className="t_d regular">Client</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BpTestimonial;
