import React from 'react'

import "./BpContactUs.scss";

const BpContactUs = () => {
  return (
    <div className="about_us sect_padding" style={{
        backgroundImage:
          "url('./assets/img/beauty-parlour/contact-us-bg.png')",
      }}>
        <div className="container">
          <div className="about_us_inner">
            <div className="sect_header text-center">
              <h2 className="title">
              Contact Us
                <img
                  src="./assets/img/beauty-parlour/icon/title_icon.png"
                  alt=""
                  className="title_icon"
                />
              </h2>
              <h6 className="desc">
                Lorem ipsum dolor sittem ametamngcing elit, per sed do eiusmoad
                teimpor sittem elit inuning ut sed sittem do eiusmod.
              </h6>
            </div>
            <div
              className="au_card" >
              <div className="left">
                <h3 className="inner_title">Address</h3>
                <h6 className="inner_desc">
                Near padmawati colony, kanipura road, ujjain, madhya pradesh
                </h6>
                <h6 className="inner_desc">
                 info@gmail.com, blessy@gmail.com
                </h6>
                <h6 className="inner_desc">
              +91 6989658965
                </h6>
              </div>
              <div className="right"></div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default BpContactUs
