import React from "react";

import "./BpServices.scss";

const BpServices = () => {
  return (
    <div className="bpservices sect_padding">
      <div className="container">
        <div className="about_us_inner">
          <div className="sect_header text-center">
            <h2 className="title">
              Our Services
              <img
                src="./assets/img/beauty-parlour/icon/title_icon.png"
                alt=""
                className="title_icon"
              />
            </h2>
            <h6 className="desc">
              Lorem ipsum dolor sittem ametamngcing elit, per sed do eiusmoad
              teimpor sittem elit inuning ut sed sittem do eiusmod.
            </h6>
          </div>
          <div className="services">
            <div className="services_single">
              <div className="icon">
                <img
                  src="./assets/img/beauty-parlour/icon/make-up-black.png"
                  alt=""
                  className="black_icon"
                />
              </div>
              <div className="card_title">Make up</div>
              <div className="card_desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                cursus tincidunt ultrices utquis blandit dolor.
              </div>
              <button className="theme_btn btn_border small_btn">
                Read More
              </button>
            </div>
            <div className="services_single">
              <div className="icon">
                <img
                  src="./assets/img/beauty-parlour/icon/mask-black.png"
                  alt=""
                  className="black_icon"
                />
              </div>
              <div className="card_title">Facial Mask</div>
              <div className="card_desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                cursus tincidunt ultrices utquis blandit dolor.
              </div>
              <button className="theme_btn btn_border small_btn">
                Read More
              </button>
            </div>
            <div className="services_single">
              <div className="icon">
                <img
                  src="./assets/img/beauty-parlour/icon/skincare-black.png"
                  alt=""
                  className="black_icon"
                />
              </div>
              <div className="card_title">Skin Care</div>
              <div className="card_desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                cursus tincidunt ultrices utquis blandit dolor.
              </div>
              <button className="theme_btn btn_border small_btn">
                Read More
              </button>
            </div>
            <div className="services_single">
              <div className="icon">
                <img
                  src="./assets/img/beauty-parlour/icon/eye-makeup-black.png"
                  alt=""
                  className="black_icon"
                />
              </div>
              <div className="card_title">Liner</div>
              <div className="card_desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                cursus tincidunt ultrices utquis blandit dolor.
              </div>
              <button className="theme_btn btn_border small_btn">
                Read More
              </button>
            </div>
            <div className="services_single">
              <div className="icon">
                <img
                  src="./assets/img/beauty-parlour/icon/makeup-kit-black.png"
                  alt=""
                  className="black_icon"
                />
              </div>
              <div className="card_title">Special event make up</div>
              <div className="card_desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                cursus tincidunt ultrices utquis blandit dolor.
              </div>
              <button className="theme_btn btn_border small_btn">
                Read More
              </button>
            </div>
            <div className="services_single">
              <div className="icon">
                <img
                  src="./assets/img/beauty-parlour/icon/home-makeup-black.png"
                  alt=""
                  className="black_icon"
                />
              </div>
              <div className="card_title">Home service</div>
              <div className="card_desc">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                cursus tincidunt ultrices utquis blandit dolor.
              </div>
              <button className="theme_btn btn_border small_btn">
                Read More
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BpServices;
