import React from 'react'

import './BpVideoSection.scss'

const BpVideoSection = () => {
  return (
    <div className='video_section'  style={{
        backgroundImage:
          "url('./assets/img/beauty-parlour/video_bg.jpg')",
      }}
      >
      
    </div>
  )
}

export default BpVideoSection
