import React from "react";

import "./BpAboutUs.scss";

const BPAboutUs = () => {
  return (
    <div className="about_us sect_padding" style={{
      backgroundImage:
        "url('./assets/img/beauty-parlour/about-bg-image.png')",
    }}>
      <div className="container">
        <div className="about_us_inner">
          <div className="sect_header text-center">
            <h2 className="title">
              About Us
              <img
                src="./assets/img/beauty-parlour/icon/title_icon.png"
                alt=""
                className="title_icon"
              />
            </h2>
            <h6 className="desc">
              Lorem ipsum dolor sittem ametamngcing elit, per sed do eiusmoad
              teimpor sittem elit inuning ut sed sittem do eiusmod.
            </h6>
          </div>
          <div
            className="au_card"
            style={{
              backgroundImage:
                "url('./assets/img/beauty-parlour/about_bg.png')",
            }}
          >
            <div className="left">
              <h3 className="inner_title">Welcome to munu beauty</h3>
              <h6 className="inner_desc">
                Lorem ipsum dolor sit amet consectetur adipisicing elit sed do
                eiusmod ncididunt ametfh consectetur.
              </h6>
              <h6 className="inner_desc">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form by
                injected humour or randomised words which don't look even
                slightly believable. If you are going to use a passage of Lorem
                Ipsum, you need to be sure there isn't anything embarrassing.
              </h6>
            </div>
            <div className="right"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BPAboutUs;
