import React from 'react'

import BPAboutUs from '../component/BPAboutUs';
import BpVideoSection from '../component/BpVideoSection';
import BpServices from '../component/BpServices';
import BpGallery from '../component/BpGallery';
import BpContactForm from '../component/BpContactForm';
import BpPriceList from '../component/BpPriceList';
import BpTestimonial from '../component/BpTestimonial';
import BpItemPurchase from '../component/BpItemPurchase';
import BpContactUs from '../component/BpContactUs';
const BpHome = () => {
  return (
    <div>
    <BPAboutUs/>
    <BpVideoSection/>
    <BpServices/>
    <BpGallery/>
    <BpContactForm/>
    <BpPriceList/>
    <BpTestimonial/>
    <BpItemPurchase/>
    <BpContactUs/>
    </div>
  )
}

export default BpHome
