import React from 'react'
import { Route, Routes } from "react-router-dom";

// import css 

import '../css/Font.scss'
import '../css/Color.scss'
import '../css/Style.scss'



// import pages 
import BpHome from '../pages/BpHome';


const BeautyParlourRoutes = () => {
    return (
        <div className='beauty-parlour'>       
        <Routes>
            <Route path="/" element={<BpHome />}></Route>
        </Routes>        
        </div>

    )
}

export default BeautyParlourRoutes
